import React from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { useModal } from 'hooks/useModal';
import EditIcon from 'icons/EditIcon';
import FileIcon from 'icons/FileIcon';
import AddFileModal from 'modules/CasesModule/components/Case/components/FilesTab/components/AddFileModal';

const FileItem = () => {
  const { isOpen, handleOpenModal, handleCloseModal } = useModal();

  return (
    <>
      <TableRow sx={{ display: 'flex', alignItems: 'center' }}>
        <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', p: '24px 0' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FileIcon sx={{ mr: '26px' }} />
            <Box>
              <Typography variant='body1'>document_file_name.pdf</Typography>
              <Typography variant='body2'>Added on January 5, 2023</Typography>
            </Box>
          </Box>
          <Box onClick={handleOpenModal} sx={{ p: '16px', cursor: 'pointer' }}>
            <EditIcon />
          </Box>
        </TableCell>
      </TableRow>
      <AddFileModal isOpen={isOpen} handleClose={handleCloseModal} />
    </>
  );
};

export default FileItem;

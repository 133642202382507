import { createTheme } from '@mui/material/styles';

// FocusVisibleOpacity: 0.3

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
          scrollBehavior: 'smooth',
        },
        body: {
          height: '100%',
          overflowY: 'scroll',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 1px 24px 0px rgba(99, 113, 138, 0.10)',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
  palette: {
    text: {
      primary: '#131736',
      secondary: '#1317368C',
      disabled: '#13173670',
    },
    primary: {
      main: '#05D279',
      dark: '#08B86C',
      light: '#87F5C6',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#9747FF',
      dark: '',
      light: '#9747FF',
      contrastText: '#FFFFFF',
    },
    action: {
      hover: '#05D27914',
      hoverOpacity: 0.04,
      selected: '#05D279',
      selectedOpacity: 0.08,
      focus: '#1317361F',
      focusOpacity: 0.12,
    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#EF5350',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#ED6C02',
      dark: '#E65100',
      light: '#FF9800',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#0288D1',
      dark: '#01579B',
      light: '#03A9F4',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#2E7D32',
      dark: '#1B5E20',
      light: '#4CAF50',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#FCF8F2',
      paper: '#FFFEFC',
    },
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 300,
      fontSize: '96px',
      lineHeight: '112px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 300,
      fontSize: '60px',
      lineHeight: '72px',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '48px',
      lineHeight: '56px',
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '34px',
      lineHeight: '42px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.1px',
    },
    body1: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '27px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.15',
    },
    button: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      textTransform: 'none',
    },
    caption: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: '0.4px',
    },
    overline: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '32px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
});

export default theme;

import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, Paper, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from 'icons/UploadIcon';
import theme from 'MuiTheme';
import FileDropZone from 'modules/CasesModule/components/Case/components/FilesTab/components/FileDropZone';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  p: '24px',
  outline: 'none',
  borderRadius: '8px',
};

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
}

const AddFileModal: FC<IProps> = ({ isOpen, handleClose }) => {
  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Paper sx={style}>
        <Box sx={{ pb: '24px' }}>
          <Typography variant='h5'>New file</Typography>
        </Box>
        <Divider />
        <Box sx={{ pb: '24px', pt: '16px' }}>
          <Typography mb='16px' variant='subtitle1'>
            Name the file
          </Typography>
          <TextField
            helperText='Kindly, name in English'
            label='File name'
            InputLabelProps={{ shrink: true }}
            placeholder='e.g. Passport, VISA'
            fullWidth
          />
        </Box>
        <Divider />
        <Box sx={{ py: '24px' }}>
          <Typography mb='16px' variant='subtitle1'>
            Upload a file
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: '16px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <UploadIcon sx={{ mr: '26px' }} />
              <Box>
                <Typography variant='body1'>document_file_name.pdf</Typography>
                <Typography variant='body2' color={theme.palette.text.secondary}>
                  700kb • Loading
                </Typography>
              </Box>
            </Box>
            <CloseIcon sx={{ color: theme.palette.text.secondary, cursor: 'pointer' }} />
          </Box>
          {/* <FileDropZone /> */}
        </Box>
        <Divider />
        <Box sx={{ pt: '24px', display: 'flex', justifyContent: 'end' }}>
          <Button onClick={handleClose} color='inherit' sx={{ mr: '8px', p: '15px 35px', fontSize: '18px' }} variant='text'>
            Cancel
          </Button>
          <Button color='error' sx={{ mr: '8px', p: '15px 35px', fontSize: '18px' }} variant='outlined'>
            Delete file
          </Button>
          <Button sx={{ p: '15px 35px', fontSize: '18px' }} variant='contained'>
            Save file
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default AddFileModal;

export enum AuthRoutes {
  profile = '/profile',
  dashboard = '/dashboard',
  tasks = '/tasks',
  cases = '/cases',
  inbox = '/inbox',
  clients = '/clients',
  feedback = '/feedback',
  support = '/support',
  teamMembers = '/team-members',
  reports = '/reports',
  notifications = '/notifications',
  caseById = 'cases/:id'
}

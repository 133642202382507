import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const ChatIcon: FC<ExtendedSvgIconProps> = (props) => {
  const { variant = 'outlined', ...restProps } = props;

  return variant === 'outlined' ? (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15.5021 9C17.2255 9 18.8783 9.68482 20.0968 10.9038C21.3153 12.1228 22 13.7761 22 15.5C22.0041 16.7795 21.626 18.031 20.9144 19.0941L22 21.9999L18.3459 21.3423C17.4671 21.771 16.503 21.9958 15.5253 21.9999C14.5476 22.0041 13.5816 21.7874 12.6992 21.3662C11.8168 20.945 11.0408 20.3298 10.4291 19.567C9.81735 18.8041 9.38559 17.9129 9.16601 16.9598C8.94642 16.0068 8.94465 15.0165 9.16085 14.0627C9.37705 13.1088 9.80564 12.2161 10.4147 11.451C11.0237 10.6859 11.7975 10.0682 12.6783 9.6438C13.5592 9.21943 14.5244 8.99936 15.5021 9Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 5.05329C14.9927 3.74651 13.6109 2.79248 12.0468 2.32401C10.4828 1.85553 8.81434 1.89594 7.27386 2.4396C5.73339 2.98326 4.39753 4.00312 3.45224 5.35718C2.50697 6.71122 1.99934 8.3321 2.00003 9.9941C1.9954 11.5739 2.45374 13.1192 3.31627 14.4317L2.00003 18L5.17096 17.4205"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.87873 2C7.78918 2 5.7852 2.83009 4.30765 4.30765C2.83011 5.78522 2.00004 7.78921 2.00004 9.87881C1.99512 11.4297 2.45344 12.9466 3.31624 14.2353L2.26352 17.0525C2.14724 17.3637 2.4139 17.6831 2.74086 17.6244L6.43064 16.9605C6.7971 17.1391 7.17579 17.2887 7.56318 17.4081C7.42831 16.8175 7.35707 16.2028 7.35707 15.5714C7.35707 11.0348 11.0347 7.35713 15.5712 7.35713C16.2052 7.35713 16.8225 7.42896 17.4152 7.56493C17.1262 6.62396 16.6628 5.74376 16.0474 4.97096C15.3089 4.04357 14.3707 3.29477 13.3026 2.78037C12.2346 2.26597 11.0642 1.99922 9.87873 2ZM20.1171 11.0257C18.9114 9.82014 17.2763 9.14284 15.5715 9.14284C14.6042 9.1422 13.6493 9.35985 12.7778 9.77957C11.9063 10.1993 11.1408 10.8102 10.5383 11.5669C9.93576 12.3236 9.51175 13.2065 9.29787 14.1499C9.08399 15.0932 9.08572 16.0726 9.30297 17.0152C9.52021 17.9578 9.94735 18.8392 10.5526 19.5937C11.1578 20.3482 11.9255 20.9565 12.7984 21.3731C13.6714 21.7897 14.6271 22.0039 15.5943 21.9999C16.5616 21.9958 17.5155 21.7735 18.3849 21.3495L21.2591 21.8667C21.5861 21.9255 21.8527 21.6061 21.7364 21.2948L20.926 19.126C21.63 18.0745 22.004 16.8368 22 15.5714C22 13.8664 21.3227 12.2313 20.1171 11.0257Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default ChatIcon;

import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const FileIcon: FC<ExtendedSvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width='27' height='32' viewBox='0 0 27 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.40541 2.43748C3.08112 2.43748 2.77011 2.56631 2.54079 2.79562C2.31148 3.02493 2.18266 3.33594 2.18266 3.66024V28.3397C2.18266 28.664 2.31148 28.975 2.54079 29.2043C2.77011 29.4336 3.08112 29.5625 3.40541 29.5625H23.5977C23.922 29.5625 24.233 29.4336 24.4623 29.2043C24.6916 28.975 24.8205 28.664 24.8205 28.3397V11.4118H16.8669C16.3032 11.4118 15.8461 10.9548 15.8461 10.391V2.43748H3.40541ZM3.40541 0.395813C2.53964 0.395813 1.70932 0.739742 1.09712 1.35194C0.48492 1.96414 0.140991 2.79446 0.140991 3.66024V28.3397C0.140991 29.2055 0.48492 30.0358 1.09712 30.648C1.70932 31.2602 2.53963 31.6041 3.40541 31.6041H23.5977C24.4635 31.6041 25.2938 31.2602 25.906 30.648C26.5182 30.0358 26.8621 29.2055 26.8621 28.3397V9.26921C26.8621 8.99847 26.7546 8.73881 26.5631 8.54737L18.7106 0.694808C18.5191 0.503365 18.2595 0.395813 17.9887 0.395813H3.40541ZM17.8878 2.75936V9.37017H24.4986L17.8878 2.75936Z'
          fill='#05D279'
        />
      </svg>
    </SvgIcon>
  );
};

export default FileIcon;

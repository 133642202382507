import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const NotificationsIcon: FC<ExtendedSvgIconProps> = (props) => {
  const { variant = 'outlined', ...restProps } = props;

  return variant === 'outlined' ? (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10 22H14"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5455 10.2222C18.5455 8.57199 17.8558 6.98934 16.6283 5.82245C15.4008 4.65555 13.736 4 12 4C10.264 4 8.59918 4.65555 7.37166 5.82245C6.14415 6.98934 5.45455 8.57199 5.45455 10.2222V15.6667C5.45455 16.2855 5.19594 16.879 4.73563 17.3166C4.27531 17.7542 3.65099 18 3 18H21C20.349 18 19.7247 17.7542 19.2644 17.3166C18.8041 16.879 18.5455 16.2855 18.5455 15.6667V10.2222Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 10C2.00086 8.43637 2.36406 6.8954 3.05958 5.50435C3.7551 4.11331 4.76299 2.91212 6 2"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 10C21.9991 8.43637 21.6359 6.8954 20.9404 5.50435C20.2449 4.11331 19.237 2.91212 18 2"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10 22H14"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5455 10.2222C18.5455 8.57199 17.8558 6.98934 16.6283 5.82245C15.4008 4.65555 13.736 4 12 4C10.264 4 8.59917 4.65555 7.37166 5.82245C6.14415 6.98934 5.45455 8.57199 5.45455 10.2222V15.6667C5.45455 16.2855 5.19594 16.879 4.73563 17.3166C4.27531 17.7542 3.65099 18 3 18H21C20.349 18 19.7247 17.7542 19.2644 17.3166C18.8041 16.879 18.5455 16.2855 18.5455 15.6667V10.2222Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 10C2.00086 8.43637 2.36406 6.8954 3.05958 5.50435C3.7551 4.11331 4.76299 2.91212 6 2"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 10C21.9991 8.43637 21.6359 6.8954 20.9404 5.50435C20.2449 4.11331 19.237 2.91212 18 2"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default NotificationsIcon;

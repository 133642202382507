import React from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FileDropZone from 'modules/CasesModule/components/Case/components/FilesTab/components/FileDropZone';
import FileList from 'modules/CasesModule/components/Case/components/FilesTab/components/FileList';
import AddFileModal from 'modules/CasesModule/components/Case/components/FilesTab/components/AddFileModal';
import { useModal } from 'hooks/useModal';

const Files = () => {
  const { isOpen, handleOpenModal, handleCloseModal } = useModal();

  return (
    <>
      <Paper sx={{ backgroundColor: '#fff', p: '24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '48px' }}>
          <Typography variant='h6'>Files</Typography>
          <Button onClick={handleOpenModal} variant='contained'>
            <AddIcon sx={{ mr: '8px' }} />
            Add file
          </Button>
        </Box>
        <Box>
          <FileDropZone />
        </Box>
        <FileList />
      </Paper>
      <AddFileModal isOpen={isOpen} handleClose={handleCloseModal} />
    </>
  );
};

export default Files;

import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const DashboardIcon: FC<ExtendedSvgIconProps> = (props) => {
  const { variant = 'outlined', ...restProps } = props;

  return variant === 'outlined' ? (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.215 11.7998C13.215 11.0219 13.8538 10.4113 14.6168 10.4113H20.5982C21.3612 10.4113 22.0001 11.0218 22.0001 11.7998V20.6116C22.0001 21.3894 21.3612 22.0001 20.5982 22.0001H14.6168C13.8537 22.0001 13.215 21.3894 13.215 20.6116V11.7998ZM14.6168 11.7197C14.5541 11.7197 14.5234 11.7666 14.5234 11.7998V20.6116C14.5234 20.6447 14.5541 20.6917 14.6168 20.6917H20.5982C20.6609 20.6917 20.6916 20.6447 20.6916 20.6116V11.7998C20.6916 11.7666 20.6609 11.7197 20.5982 11.7197H14.6168Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.215 3.43044C13.215 2.66377 13.8197 2 14.6168 2H20.5982C21.3952 2 22.0001 2.66373 22.0001 3.43044V6.5509C22.0001 7.3176 21.3952 7.98134 20.5982 7.98134H14.6168C13.8197 7.98134 13.215 7.31757 13.215 6.5509V3.43044ZM14.5593 3.33541C14.5391 3.35638 14.5234 3.38867 14.5234 3.43044V6.5509C14.5234 6.59266 14.5391 6.62496 14.5593 6.64593C14.5791 6.66647 14.5991 6.67292 14.6168 6.67292H20.5982C20.6159 6.67292 20.6359 6.66649 20.6557 6.64592C20.6759 6.62492 20.6916 6.59263 20.6916 6.5509V3.43044C20.6916 3.3887 20.6759 3.35641 20.6557 3.33542C20.6359 3.31485 20.6159 3.30842 20.5982 3.30842H14.6168C14.5991 3.30842 14.5791 3.31487 14.5593 3.33541Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3.38852C2 2.6106 2.6388 2 3.40188 2H9.38321C10.1463 2 10.7851 2.6106 10.7851 3.38852V12.2003C10.7851 12.9782 10.1463 13.5888 9.38321 13.5888H3.40188C2.6388 13.5888 2 12.9782 2 12.2003V3.38852ZM3.40188 3.30842C3.3391 3.30842 3.30842 3.35535 3.30842 3.38852V12.2003C3.30842 12.2335 3.3391 12.2804 3.40188 12.2804H9.38321C9.44599 12.2804 9.47667 12.2335 9.47667 12.2003V3.38852C9.47667 3.35535 9.44599 3.30842 9.38321 3.30842H3.40188Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 17.4492C2 16.6824 2.60479 16.0188 3.40188 16.0188H9.38321C10.1803 16.0188 10.7851 16.6824 10.7851 17.4492V20.5697C10.7851 21.3363 10.1804 22.0001 9.38321 22.0001H3.40188C2.6047 22.0001 2 21.3363 2 20.5697V17.4492ZM3.34436 17.3542C3.3242 17.3751 3.30842 17.4074 3.30842 17.4492V20.5697C3.30842 20.6114 3.32417 20.6437 3.34439 20.6647C3.3642 20.6853 3.38415 20.6917 3.40188 20.6917H9.38321C9.40094 20.6917 9.42089 20.6853 9.4407 20.6647C9.46092 20.6437 9.47667 20.6114 9.47667 20.5697V17.4492C9.47667 17.4074 9.46089 17.3751 9.44073 17.3542C9.42097 17.3337 9.40103 17.3272 9.38321 17.3272H3.40188C3.38406 17.3272 3.36412 17.3337 3.34436 17.3542Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.00007 4.24967C3.00007 3.54954 3.575 3 4.26176 3H9.64497C10.3317 3 10.9067 3.54954 10.9067 4.24967V12.1803C10.9067 12.8804 10.3317 13.43 9.64497 13.43H4.26176C3.575 13.43 3.00007 12.8804 3.00007 12.1803V4.24967ZM14.3552 3C13.6378 3 13.0935 3.59739 13.0935 4.2874V7.09581C13.0935 7.78582 13.6378 8.38321 14.3552 8.38321H19.7384C20.4557 8.38321 21.0001 7.78585 21.0001 7.09581V4.2874C21.0001 3.59736 20.4557 3 19.7384 3H14.3552ZM14.3552 10.5701C13.6684 10.5701 13.0935 11.1197 13.0935 11.8198V19.7504C13.0935 20.4505 13.6684 21.0001 14.3552 21.0001H19.7384C20.4251 21.0001 21.0001 20.4505 21.0001 19.7504V11.8198C21.0001 11.1197 20.4251 10.5701 19.7384 10.5701H14.3552ZM4.26169 15.6169C3.54431 15.6169 3 16.2142 3 16.9043V19.7127C3 20.4026 3.54423 21.0001 4.26169 21.0001H9.6449C10.3623 21.0001 10.9066 20.4026 10.9066 19.7127V16.9043C10.9066 16.2142 10.3623 15.6169 9.6449 15.6169H4.26169Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default DashboardIcon;

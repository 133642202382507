import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const CasesIcon: FC<ExtendedSvgIconProps> = (props) => {
  const { variant = 'outlined', ...restProps } = props;

  return variant === 'outlined' ? (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66672 2.70005C8.41034 2.70005 8.16448 2.80189 7.98318 2.98317C7.80189 3.16446 7.70005 3.41034 7.70005 3.66672V6.30005H16.3V3.66672C16.3 3.41034 16.1982 3.16446 16.0169 2.98317C15.8356 2.80189 15.5898 2.70005 15.3334 2.70005H8.66672ZM17.7 6.30005V3.66672C17.7 3.03902 17.4507 2.43707 17.0069 1.99324C16.563 1.54941 15.9611 1.30005 15.3334 1.30005H8.66672C8.03903 1.30005 7.43707 1.5494 6.99323 1.99322C6.54942 2.43705 6.30005 3.03902 6.30005 3.66672V6.30005H3.53851C2.31905 6.30005 1.30005 7.26843 1.30005 8.50005V20.5C1.30005 21.7317 2.31906 22.7001 3.53851 22.7001H20.4616C21.6811 22.7001 22.7001 21.7317 22.7001 20.5V8.50005C22.7001 7.26844 21.6811 6.30005 20.4616 6.30005H17.7ZM3.53851 7.70005C3.05863 7.70005 2.70005 8.07483 2.70005 8.50005V20.5C2.70005 20.9253 3.05862 21.3 3.53851 21.3H20.4616C20.9415 21.3 21.3 20.9253 21.3 20.5V8.50005C21.3 8.07482 20.9415 7.70005 20.4616 7.70005H3.53851ZM6.30005 12C6.30005 11.6134 6.61345 11.3 7.00005 11.3H17C17.3866 11.3 17.7 11.6134 17.7 12C17.7 12.3866 17.3866 12.7 17 12.7H7.00005C6.61345 12.7 6.30005 12.3866 6.30005 12ZM6.30005 17C6.30005 16.6134 6.61345 16.3 7.00005 16.3H17C17.3866 16.3 17.7 16.6134 17.7 17C17.7 17.3866 17.3866 17.7 17 17.7H7.00005C6.61345 17.7 6.30005 17.3866 6.30005 17Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66672 2.70005C8.41034 2.70005 8.16448 2.80189 7.98318 2.98317C7.80189 3.16446 7.70005 3.41034 7.70005 3.66672V6.30005H16.3V3.66672C16.3 3.41034 16.1982 3.16446 16.0169 2.98317C15.8356 2.80189 15.5898 2.70005 15.3334 2.70005H8.66672ZM17.7001 6.30005V3.66672C17.7001 3.03902 17.4507 2.43707 17.0069 1.99324C16.563 1.54941 15.9611 1.30005 15.3334 1.30005H8.66672C8.03903 1.30005 7.43707 1.5494 6.99323 1.99322C6.54942 2.43705 6.30005 3.03902 6.30005 3.66672V6.30005H3.53851C2.31905 6.30005 1.30005 7.26843 1.30005 8.50005V20.5C1.30005 21.7317 2.31906 22.7001 3.53851 22.7001H20.4616C21.6811 22.7001 22.7001 21.7317 22.7001 20.5V8.50005C22.7001 7.26844 21.6811 6.30005 20.4616 6.30005H17.7001ZM6.30006 12.0001C6.30006 11.6135 6.61346 11.3001 7.00006 11.3001H17.0001C17.3867 11.3001 17.7001 11.6135 17.7001 12.0001C17.7001 12.3867 17.3867 12.7001 17.0001 12.7001H7.00006C6.61346 12.7001 6.30006 12.3867 6.30006 12.0001ZM6.30006 17.0001C6.30006 16.6135 6.61346 16.3001 7.00006 16.3001H17.0001C17.3867 16.3001 17.7001 16.6135 17.7001 17.0001C17.7001 17.3867 17.3867 17.7001 17.0001 17.7001H7.00006C6.61346 17.7001 6.30006 17.3867 6.30006 17.0001Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default CasesIcon;

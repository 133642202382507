import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button } from '@mui/material';

const Dashboard = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  return (
    <Box padding="20px">
      <Box>Dashboard</Box>
      <Button onClick={handleLogout}>Log Out</Button>
    </Box>
  );
};

export default Dashboard;

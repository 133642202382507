import { Box, Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import mockedImage from 'assets/_Avatar_.jpg';
import warnIcon from 'assets/warning-icon.svg';
import { CircleFlag } from 'react-circle-flags';
import StatusPill from 'components/StatusPill/StatusPill';
import { useNavigate } from 'react-router-dom';

const flexStyles = { display: 'flex', alignItems: 'center' };

const CasesListItem = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/cases/1');
  };

  return (
    <TableRow
      sx={{ display: 'flex', width: '100%', height: '72px' }}
      onClick={handleClick}
    >
      <TableCell width="25%" sx={flexStyles}>
        <Checkbox onClick={(e) => e.stopPropagation()} />
        <Box sx={flexStyles}>
          <img src={mockedImage} />
        </Box>
        <Box ml="8px">
          <Typography variant="body2">Prabodhan Fitzgerald</Typography>
          <CircleFlag countryCode="ua" width="20px" height="20px" />
        </Box>
      </TableCell>
      <TableCell width="12.5%" sx={flexStyles}>
        <Typography variant="body2">Volvo</Typography>
      </TableCell>
      <TableCell width="12.5%" sx={flexStyles}>
        <Typography variant="body2">15/02/2023</Typography>
      </TableCell>
      <TableCell width="12.5%" sx={flexStyles}>
        <StatusPill status="active" />
      </TableCell>
      <TableCell width="12.5%" sx={flexStyles}>
        <Typography variant="body2">Consultation</Typography>
      </TableCell>
      <TableCell width="12.5%" sx={flexStyles}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={warnIcon} />
          <Typography ml="8px" variant="body2">
            escalation
          </Typography>
        </Box>
      </TableCell>
      <TableCell width="12.5%" sx={flexStyles}>
        <Typography variant="body2">AF</Typography>
      </TableCell>
    </TableRow>
  );
};

export default CasesListItem;

import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const EditIcon: FC<ExtendedSvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.4499 19.0001H18.5908M7.23292 17.2556L0.999939 19.0001L2.74517 12.7697L14.1585 1.41663C14.2875 1.28476 14.4415 1.17998 14.6116 1.10845C14.7816 1.03691 14.9643 1.00006 15.1488 1.00006C15.3333 1.00006 15.516 1.03691 15.686 1.10845C15.8561 1.17998 16.0102 1.28476 16.1392 1.41663L18.5908 3.88108C18.7205 4.00988 18.8234 4.16304 18.8936 4.33175C18.9638 4.50045 18.9999 4.68137 18.9999 4.86409C18.9999 5.04681 18.9638 5.22773 18.8936 5.39643C18.8234 5.56514 18.7205 5.7183 18.5908 5.8471L7.23292 17.2556Z'
          stroke='#131736'
          strokeOpacity='0.56'
          strokeWidth='1.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};

export default EditIcon;

import { Chip } from '@mui/material';
import { FC } from 'react';

const statuses = {
  draft: {
    bgColor: '#F5F5F5',
    textColor: '#757575',
    statusText: 'Draft',
  },
  active: {
    bgColor: '#F0FEFB',
    textColor: '#00B88A',
    statusText: 'Active',
  },
  onHold: {
    bgColor: '#FFFAEA',
    textColor: '#E89A00',
    statusText: 'On hold',
  },
  canceled: {
    bgColor: '#FFF0F3',
    textColor: '#F6482F',
    statusText: 'Canceled',
  },
  completed: {
    bgColor: '#E7FCFF',
    textColor: '#00ACC1',
    statusText: 'Completed',
  },
};

interface IProps {
  status: 'draft' | 'active' | 'onHold' | 'canceled' | 'completed';
}

const StatusPill: FC<IProps> = ({ status }) => {
  return (
    <Chip
      sx={{
        bgcolor: statuses[status].bgColor,
        color: statuses[status].textColor,
      }}
      label={statuses[status].statusText}
    />
  );
};

export default StatusPill;

import React, { FC, useState } from 'react';
import { Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

const sortValues = ['Default', 'Date (newest)', 'Date (oldest)', 'A to Z', 'Z to A'];
const statusValues = ['Draft', 'Active', 'On hold', 'Canceled', 'Completed'];

const styles = {
  container: {
    padding: '24px',
    display: 'flex',
  },
  searchField: {
    width: '300px',
    marginRight: '16px',
  },
  selectField: {
    width: '180px',
    marginRight: '16px',
  },
};

const CasesFilterBar: FC = () => {
  const [selectedSortValue, setSelectedSortValue] = useState<string>('');
  const [selectedStatusValue, setSelectedStatusValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChangeSortValue = (e: SelectChangeEvent<string>) => setSelectedSortValue(e.target.value);
  const handleChangeStatusValue = (e: SelectChangeEvent<string>) => setSelectedStatusValue(e.target.value);
  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearchValue(e.target.value);

  return (
    <Box sx={styles.container}>
      <TextField
        value={searchValue}
        onChange={handleChangeSearchValue}
        sx={styles.searchField}
        placeholder='Search'
        size='small'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant='outlined'
      />
      <FormControl size='small' sx={styles.selectField}>
        <InputLabel id='sort-label'>Sort by</InputLabel>
        <Select defaultValue='' labelId='sort-label' label='Sort by' value={selectedSortValue} onChange={handleChangeSortValue}>
          {sortValues.map((sortValue) => (
            <MenuItem key={sortValue} value={sortValue}>
              {sortValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size='small' sx={styles.selectField}>
        <InputLabel id='filter-label'>Filter by status</InputLabel>
        <Select
          defaultValue=''
          labelId='filter-label'
          label='Filter by status'
          value={selectedStatusValue}
          onChange={handleChangeStatusValue}
        >
          {statusValues.map((statusValue) => (
            <MenuItem key={statusValue} value={statusValue}>
              {statusValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button sx={{ ml: 'auto' }} variant='contained'>
        <AddIcon sx={{ mr: '8px' }} />
        New case
      </Button>
    </Box>
  );
};

export default CasesFilterBar;

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType } from 'react';

export const AuthenticationGuard = ({
  component: Component,
}: {
  component: ComponentType<object>;
}) => {

  // For demo purposes
  // const Component = withAuthenticationRequired(component, {
  //   onRedirecting: () => <div>Loading...</div>,
  // });
  

  return <Component />;
};

import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import CaseTabs from 'modules/CasesModule/components/Case/components/CaseTabs/CaseTabs';

const Case = () => {
  const { id } = useParams();

  return (
    <>
      <Typography>Case id: {id}</Typography>
      <CaseTabs />
    </>
  );
};

export default Case;

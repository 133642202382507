import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import StatusPill from 'components/StatusPill/StatusPill';
import mockedImage from 'assets/_Avatar_.jpg';

const flexStyles = { display: 'flex', alignItems: 'center' };

const FamilyTableRow = () => {
  return (
    <TableRow sx={{ display: 'flex', width: '100%', height: '72px' }}>
      <TableCell width="15%" sx={flexStyles}>
        <Checkbox />
        <Typography variant="body2">Spouse</Typography>
      </TableCell>
      <TableCell width="25%" sx={flexStyles}>
        <img src={mockedImage} />
        <Typography variant="body2" marginLeft="8px">
          Prabodhan Fitzgerald
        </Typography>
      </TableCell>
      <TableCell width="15%" sx={flexStyles}>
        <StatusPill status="active" />
      </TableCell>
      <TableCell width="15%" sx={flexStyles}>
        <Typography variant="body2">Immigration</Typography>
      </TableCell>
      <TableCell width="15%" sx={flexStyles}>
        <Typography variant="body2">-</Typography>
      </TableCell>
      <TableCell width="15%" sx={flexStyles}>
        <Typography variant="body2">AF</Typography>
      </TableCell>
    </TableRow>
  );
};

export default FamilyTableRow;

import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const UploadIcon: FC<ExtendedSvgIconProps> = (props) => {

  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.63617 2.69993C4.37437 2.69993 4.13133 2.79811 3.95857 2.96053C3.78727 3.12157 3.6998 3.33044 3.6998 3.53839V20.4615C3.6998 20.6694 3.78727 20.8783 3.95857 21.0393C4.13133 21.2017 4.37437 21.2999 4.63617 21.2999H19.3634C19.6252 21.2999 19.8683 21.2017 20.041 21.0393C20.2123 20.8783 20.2998 20.6694 20.2998 20.4615V7.68721L14.9951 2.69993H4.63617ZM2.9996 1.94054C3.4406 1.52592 4.02998 1.29993 4.63617 1.29993H15.2725C15.4507 1.29993 15.6222 1.36788 15.752 1.48993L21.4793 6.87455C21.62 7.00684 21.6998 7.1914 21.6998 7.38454V20.4615C21.6998 21.0696 21.4425 21.6433 21 22.0593C20.559 22.4739 19.9696 22.6999 19.3634 22.6999H4.63617C4.02998 22.6999 3.4406 22.4739 2.9996 22.0593C2.55714 21.6433 2.2998 21.0696 2.2998 20.4615V3.53839C2.2998 2.93029 2.55714 2.35652 2.9996 1.94054ZM11.5048 7.50495C11.7782 7.23159 12.2214 7.23159 12.4948 7.50495L15.4948 10.505C15.7681 10.7783 15.7681 11.2215 15.4948 11.4949C15.2214 11.7683 14.7782 11.7683 14.5048 11.4949L12.6998 9.68988V15.9999C12.6998 16.3865 12.3864 16.6999 11.9998 16.6999C11.6132 16.6999 11.2998 16.3865 11.2998 15.9999V9.68988L9.49478 11.4949C9.22141 11.7683 8.7782 11.7683 8.50483 11.4949C8.23146 11.2215 8.23146 10.7783 8.50483 10.505L11.5048 7.50495Z'
          fill='#05D279'
        />
      </svg>
    </SvgIcon>
  );
};

export default UploadIcon;

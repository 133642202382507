import { Box } from '@mui/material';
import { NavBar } from 'components/NavBar/NavBar';
import { Outlet } from 'react-router-dom';

export const PageLayout = () => {
  return (
    <Box display="flex">
      <NavBar />
      <Box sx={{width: '100%'}} component="main">
        <Outlet />
      </Box>
    </Box>
  );
};

import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  textDecoration: 'none',
  padding: '8px 16px',
  marginBottom: '4px',
  borderRadius: '8px',
  width: '100%',
  alignItems: 'center',
  height: '51px',
  color: theme.palette.text.secondary,

  ':hover': {
    backgroundColor: theme.palette.action.focus + '!important',
    color: theme.palette.text.primary + '!important',
  },
}));

import CasesTable from 'modules/CasesModule/components/Cases/components/CasesTable';
import { Box, Typography } from '@mui/material';
import theme from 'MuiTheme';

const Cases = () => {
  console.log('Here');
  return (
    <Box
      sx={{
        pt: '32px',
        px: '20px',
        pb: '20px',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Typography mb={'28px'} variant="h5">
        Cases
      </Typography>
      <CasesTable />
    </Box>
  );
};

export default Cases;

import {
  CasesIcon,
  ChatIcon,
  DashboardIcon,
  FeedbackIcon,
  NotificationsIcon,
  ReportIcon,
  SupportIcon,
  TeamIcon,
  UserIcon,
} from 'icons';
import { FC } from 'react';
import { AuthRoutes } from 'routes/constants';
import { ExtendedSvgIconProps } from 'types/general';
import { UserRoles } from 'types/user';

interface NavigationItem {
  path: AuthRoutes;
  icon: FC<ExtendedSvgIconProps>;
  roles: UserRoles[];
  text: string;
}

export const roles = [
  UserRoles.admin,
  UserRoles.assistant,
  UserRoles.companyHr,
  UserRoles.consultant,
  UserRoles.talent,
  UserRoles.teamLead,
];

export const defaultNavigationItems: NavigationItem[] = [
  {
    path: AuthRoutes.dashboard,
    roles: roles,
    icon: DashboardIcon,
    text: 'Dashboard',
  },
  {
    path: AuthRoutes.cases,
    roles: roles,
    icon: CasesIcon,
    text: 'Cases',
  },
  {
    path: AuthRoutes.inbox,
    roles: roles,
    icon: ChatIcon,
    text: 'Inbox',
  },
  {
    path: AuthRoutes.teamMembers,
    roles: roles,
    icon: TeamIcon,
    text: 'Team members',
  },
  {
    path: AuthRoutes.clients,
    roles: roles,
    icon: UserIcon,
    text: 'Clients',
  },
  {
    path: AuthRoutes.feedback,
    roles: roles,
    icon: FeedbackIcon,
    text: 'Feedback',
  },
  {
    path: AuthRoutes.reports,
    roles: roles,
    icon: ReportIcon,
    text: 'Reports',
  },
  {
    path: AuthRoutes.notifications,
    roles: roles,
    icon: NotificationsIcon,
    text: 'Notifications',
  },
  {
    path: AuthRoutes.support,
    roles: roles,
    icon: SupportIcon,
    text: 'Support',
  },
];

import { Avatar, Box, Divider, Typography } from '@mui/material';
import logoipsum from 'assets/logoipsum.svg';
import { defaultNavigationItems } from './mock';
import { UserRoles } from 'types/user';
import { getAccessiblePages } from 'utils/user';
import { NavBarItem } from './NavBarItem';
import { StyledNavLink } from './styles';

export const NavBar = () => {
  const navigationItems = getAccessiblePages(
    UserRoles.admin,
    defaultNavigationItems
  );

  return (
    <Box
      width="248px"
      height="100vh"
      display="flex"
      alignItems="center"
      flexDirection="column"
      bgcolor="primary.contrastText"
      padding="8px"
      position="sticky"
      top="0"
    >
      <Box
        component="img"
        padding="0 16px"
        margin="42px 0"
        alignSelf="flex-start"
        src={logoipsum}
      />
      <StyledNavLink
        to="/profile"
        sx={{ marginBottom: '8px' }}
        style={({ isActive }) => {
          return {
            backgroundColor: isActive ? '#05D27914' : 'initial',
            pointerEvents: isActive ? 'none' : 'all',
          };
        }}
      >
        {({ isActive }) => (
          <>
            <Avatar sx={{ width: '32px', height: '32px' }}>OP</Avatar>
            <Typography
              color={isActive ? 'primary.dark' : undefined}
              marginLeft="24px"
              variant="body1"
            >
              My Profile
            </Typography>
          </>
        )}
      </StyledNavLink>
      <Divider flexItem variant="middle" sx={{ width: '200px' }} />
      <Box marginTop="8px" width="100%">
        {navigationItems.map(({ path, text, icon }) => (
          <NavBarItem key={path + text} path={path} icon={icon} text={text} />
        ))}
      </Box>
    </Box>
  );
};

import { Table, TableBody, TableContainer, Paper } from '@mui/material';
import FamilyTableHead from './components/FamilyTableHead';
import FamilyTableRow from './components/FamilyTableRow';

const FamilyTab = () => {
  return (
    <Paper sx={{ backgroundColor: '#fff' }}>
      <TableContainer>
        <Table>
          <FamilyTableHead />
          <TableBody>
            <FamilyTableRow />
            <FamilyTableRow />
            <FamilyTableRow />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default FamilyTab;

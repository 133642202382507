import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'MuiTheme';
import { Provider } from 'react-redux';
import { AppRouter } from 'routes/AppRouter';
import { store } from 'store/store';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
    </Provider>
  );
}

export default App;

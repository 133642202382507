import { Box, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import Files from 'modules/CasesModule/components/Case/components/FilesTab/Files';
import FamilyTab from '../FamilyTab/FamilyTab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      padding="28px 20px"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CaseTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Details" {...a11yProps(1)} />
          <Tab label="Activity" {...a11yProps(2)} />
          <Tab label="Family" {...a11yProps(3)} />
          <Tab label="Files" {...a11yProps(4)} />
          <Tab label="Forms" {...a11yProps(5)} />
          <Tab label="Conversations" {...a11yProps(6)} />
          <Tab label="Invoice" {...a11yProps(7)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        Overview
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Details
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Activity
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <FamilyTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Files />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        Forms
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        Conversations
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        Invoice
      </CustomTabPanel>
    </Box>
  );
};

export default CaseTabs;

import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const FeedbackIcon: FC<ExtendedSvgIconProps> = (props) => {
  const { variant = 'outlined', ...restProps } = props;

  return variant === 'outlined' ? (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M8.15385 20.4615L2 22L3.53846 17.3846V4.53846C3.53846 4.13044 3.70055 3.73912 3.98907 3.4506C4.27758 3.16209 4.6689 3 5.07692 3H20.4615C20.8696 3 21.2609 3.16209 21.5494 3.4506C21.8379 3.73912 22 4.13044 22 4.53846V18.9231C22 19.3311 21.8379 19.7224 21.5494 20.0109C21.2609 20.2995 20.8696 20.4615 20.4615 20.4615H8.15385Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 10H18"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 14H14"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.49403 2.95421C3.91383 2.53442 4.48319 2.29858 5.07686 2.29858H20.4615C21.0552 2.29858 21.6245 2.53442 22.0443 2.95421C22.4641 3.37401 22.6999 3.94337 22.6999 4.53704V18.9217C22.6999 19.5153 22.4641 20.0847 22.0443 20.5045C21.6245 20.9243 21.0552 21.1601 20.4615 21.1601H8.23996L2.16972 22.6777C1.92186 22.7397 1.65993 22.6617 1.48634 22.4742C1.31275 22.2868 1.25507 22.0196 1.33586 21.7772L2.8384 17.2696V4.53704C2.8384 3.94337 3.07424 3.37401 3.49403 2.95421ZM8.3 10C8.3 9.6134 8.6134 9.3 9 9.3H18C18.3866 9.3 18.7 9.6134 18.7 10C18.7 10.3866 18.3866 10.7 18 10.7H9C8.6134 10.7 8.3 10.3866 8.3 10ZM8.3 14C8.3 13.6134 8.6134 13.3 9 13.3H14C14.3866 13.3 14.7 13.6134 14.7 14C14.7 14.3866 14.3866 14.7 14 14.7H9C8.6134 14.7 8.3 14.3866 8.3 14Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default FeedbackIcon;

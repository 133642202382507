import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { routes as appRoutes } from './routes';
import { UserRoles } from 'types/user';
import { getAccessiblePages } from 'utils/user';
import { PageLayout } from 'components/PageLayout/PageLayout';
import { Auth0ProviderWithNavigate } from 'modules/LoginModule/Auth0ProviderWithNavigate';
import { AuthenticationGuard } from 'modules/LoginModule/AuthenticationGuard';
import { AuthRoutes } from './constants';

export const AppRouter = () => {
  const protectedRoutes = getAccessiblePages(UserRoles.admin, appRoutes);

  return (
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <Routes>
          {protectedRoutes.map(({ path, component }) => (
            <Route
              key={path}
              element={<AuthenticationGuard component={PageLayout} />}
            >
              <Route path={path} element={component} />
            </Route>
          ))}
          <Route path="/" element={<Navigate to={AuthRoutes.dashboard} />} />
          <Route
            path="*"
            element={
              <div>
                <h2>404 Page not found</h2>
              </div>
            }
          />
        </Routes>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  );
};

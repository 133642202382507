import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const UserIcon: FC<ExtendedSvgIconProps> = (props) => {
  const { variant = 'outlined', ...restProps } = props;

  return variant === 'outlined' ? (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12C1 5.92487 5.92487 1 12 1C18.0752 1 23 5.92487 23 12C23 18.0752 18.0752 23 12 23C5.92487 23 1 18.0752 1 12ZM12 2.43925C6.71975 2.43925 2.43925 6.71975 2.43925 12C2.43925 14.4105 3.3313 16.6126 4.80328 18.2943C5.59205 17.749 6.57529 17.3117 7.67531 16.9975C9.00611 16.6172 10.4944 16.4206 12 16.4206C13.5055 16.4206 14.9939 16.6172 16.3247 16.9975C17.4248 17.3118 18.4079 17.749 19.1967 18.2943C20.6687 16.6127 21.5607 14.4105 21.5607 12C21.5607 6.71974 17.2803 2.43925 12 2.43925ZM18.1447 19.325C17.5506 18.9553 16.8034 18.6311 15.9293 18.3814L15.9292 18.3814C14.7395 18.0414 13.3854 17.8598 12 17.8598C10.6145 17.8598 9.26048 18.0414 8.07075 18.3814L8.07069 18.3814C7.19663 18.6311 6.44941 18.9553 5.85534 19.325C7.51701 20.7204 9.66037 21.5607 12 21.5607C14.3397 21.5607 16.483 20.7204 18.1447 19.325ZM7.16822 9.94392C7.16822 7.27541 9.33149 5.11215 12 5.11215C14.6685 5.11215 16.8318 7.27541 16.8318 9.94392C16.8318 12.6124 14.6685 14.7757 12 14.7757C9.33149 14.7757 7.16822 12.6124 7.16822 9.94392ZM12 6.5514C10.1264 6.5514 8.60748 8.07029 8.60748 9.94392C8.60748 11.8176 10.1264 13.3364 12 13.3364C13.8736 13.3364 15.3925 11.8176 15.3925 9.94392C15.3925 8.07029 13.8736 6.5514 12 6.5514Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 12C23 14.9494 21.8392 17.6276 19.9497 19.6029C17.954 21.689 15.1455 22.9909 12.0328 23C12.0219 23 12.011 23 12 23C11.989 23 11.9781 23 11.9672 23C8.85447 22.9909 6.04595 21.689 4.05035 19.6029C2.16075 17.6276 1 14.9494 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM18.6673 17.5C17.0822 15.5805 14.6839 14.3571 12 14.3571C9.31609 14.3571 6.91791 15.5805 5.33266 17.5C6.91791 19.4195 9.31609 20.6429 12 20.6429C14.6839 20.6429 17.0822 19.4195 18.6673 17.5ZM12.0002 12.7857C14.1699 12.7857 15.9288 11.0268 15.9288 8.8571C15.9288 6.68741 14.1699 4.92852 12.0002 4.92852C9.8305 4.92852 8.07162 6.68741 8.07162 8.8571C8.07162 11.0268 9.8305 12.7857 12.0002 12.7857Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default UserIcon;

import React from 'react';
import CasesListItem from 'modules/CasesModule/components/Cases/components/CasesListItem';
import { Table, TableBody, TableContainer } from '@mui/material';
import CasesListHeader from 'modules/CasesModule/components/Cases/components/CasesListHeader';
import CasesPagination from 'modules/CasesModule/components/Cases/components/CasesPagination';

const CasesList = () => {
  return (
    <>
      <TableContainer>
        <Table>
          <CasesListHeader />
          <TableBody>
            <CasesListItem />
            <CasesListItem />
            <CasesListItem />
          </TableBody>
        </Table>
      </TableContainer>
      <CasesPagination count={100} page={1} onPageChange={() => ''} rowsPerPage={10} onRowsPerPageChange={() => ''} />
    </>
  );
};

export default CasesList;

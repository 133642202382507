import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import FileItem from 'modules/CasesModule/components/Case/components/FilesTab/components/FileItem';

const FileList = () => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <FileItem />
          <FileItem />
          <FileItem />
          <FileItem />
          <FileItem />
          <FileItem />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FileList;

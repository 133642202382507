import { SvgIcon } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';

const TeamIcon: FC<ExtendedSvgIconProps> = (props) => {
  const { variant = 'outlined', ...restProps } = props;

  return variant === 'outlined' ? (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9.2381 14H2.7619C2.55984 14 2.36604 14.0803 2.22316 14.2232C2.08027 14.366 2 14.5598 2 14.7619V21.2381C2 21.4402 2.08027 21.634 2.22316 21.7768C2.36604 21.9197 2.55984 22 2.7619 22H9.2381C9.44017 22 9.63396 21.9197 9.77684 21.7768C9.91973 21.634 10 21.4402 10 21.2381V14.7619C10 14.5598 9.91973 14.366 9.77684 14.2232C9.63396 14.0803 9.44017 14 9.2381 14Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2381 14H14.7619C14.5598 14 14.366 14.0803 14.2232 14.2232C14.0803 14.366 14 14.5598 14 14.7619V21.2381C14 21.4402 14.0803 21.634 14.2232 21.7768C14.366 21.9197 14.5598 22 14.7619 22H21.2381C21.4402 22 21.634 21.9197 21.7768 21.7768C21.9197 21.634 22 21.4402 22 21.2381V14.7619C22 14.5598 21.9197 14.366 21.7768 14.2232C21.634 14.0803 21.4402 14 21.2381 14Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2381 2H8.7619C8.55984 2 8.36604 2.08027 8.22316 2.22316C8.08027 2.36604 8 2.55984 8 2.7619V9.2381C8 9.44017 8.08027 9.63396 8.22316 9.77684C8.36604 9.91973 8.55984 10 8.7619 10H15.2381C15.4402 10 15.634 9.91973 15.7768 9.77684C15.9197 9.63396 16 9.44017 16 9.2381V2.7619C16 2.55984 15.9197 2.36604 15.7768 2.22316C15.634 2.08027 15.4402 2 15.2381 2Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon {...restProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9.2381 14H2.7619C2.55984 14 2.36604 14.0803 2.22316 14.2232C2.08027 14.366 2 14.5598 2 14.7619V21.2381C2 21.4402 2.08027 21.634 2.22316 21.7768C2.36604 21.9197 2.55984 22 2.7619 22H9.2381C9.44017 22 9.63396 21.9197 9.77684 21.7768C9.91973 21.634 10 21.4402 10 21.2381V14.7619C10 14.5598 9.91973 14.366 9.77684 14.2232C9.63396 14.0803 9.44017 14 9.2381 14Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2381 14H14.7619C14.5598 14 14.366 14.0803 14.2232 14.2232C14.0803 14.366 14 14.5598 14 14.7619V21.2381C14 21.4402 14.0803 21.634 14.2232 21.7768C14.366 21.9197 14.5598 22 14.7619 22H21.2381C21.4402 22 21.634 21.9197 21.7768 21.7768C21.9197 21.634 22 21.4402 22 21.2381V14.7619C22 14.5598 21.9197 14.366 21.7768 14.2232C21.634 14.0803 21.4402 14 21.2381 14Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2381 2H8.7619C8.55984 2 8.36604 2.08027 8.22316 2.22316C8.08027 2.36604 8 2.55984 8 2.7619V9.2381C8 9.44017 8.08027 9.63396 8.22316 9.77684C8.36604 9.91973 8.55984 10 8.7619 10H15.2381C15.4402 10 15.634 9.91973 15.7768 9.77684C15.9197 9.63396 16 9.44017 16 9.2381V2.7619C16 2.55984 15.9197 2.36604 15.7768 2.22316C15.634 2.08027 15.4402 2 15.2381 2Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default TeamIcon;

import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const flexStyles = { display: 'flex', alignItems: 'center' };

const FamilyTableHead = () => {
  return (
    <TableHead>
      <TableRow sx={{ display: 'flex', width: '100%', height: '56px' }}>
        <TableCell width="15%" sx={flexStyles}>
          <Checkbox />
          <Typography variant="body2">Family member</Typography>
        </TableCell>
        <TableCell width="25%" sx={flexStyles}>
          <Typography variant="body2">Dependent</Typography>
        </TableCell>
        <TableCell width="15%" sx={flexStyles}>
          <Typography variant="body2">Status</Typography>
        </TableCell>
        <TableCell width="15%" sx={flexStyles}>
          <Typography variant="body2">Case type</Typography>
        </TableCell>
        <TableCell width="15%" sx={flexStyles}>
          <Typography variant="body2">Priority</Typography>
        </TableCell>
        <TableCell width="15%" sx={flexStyles}>
          <Typography variant="body2">Agent</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FamilyTableHead;

import React, { FC, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import UploadIcon from 'icons/UploadIcon';
import { useDropzone } from 'react-dropzone';
import theme from 'MuiTheme';
import AddFileModal from 'modules/CasesModule/components/Case/components/FilesTab/components/AddFileModal';
import { useModal } from 'hooks/useModal';

const boxStyles = {
  p: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px dashed rgba(19, 23, 54, 0.06)',
  borderRadius: '8px',
  cursor: 'pointer',
};



const FileDropZone: FC = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const { isOpen, handleOpenModal, handleCloseModal } = useModal();

  useEffect(() => {
    acceptedFiles.length && handleOpenModal();
  }, [acceptedFiles]);

  return (
    <>
      <Box sx={boxStyles} {...getRootProps({ className: 'dropzone' })}>
        <Box sx={{ width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <UploadIcon />
        </Box>
        <input type='file' onClick={(e) => e.preventDefault()} {...getInputProps()} />
        <Typography my='8px' variant='subtitle1'>
          <Typography component='span' color={theme.palette.primary.main} variant='subtitle1'>
            Click to upload{' '}
          </Typography>
          or drag and drop
        </Typography>
        <Typography color={theme.palette.text.secondary} variant='body2'>
          SVG, PNG, JPG or PDF (max. 50MB)
        </Typography>
      </Box>
      <AddFileModal isOpen={isOpen} handleClose={handleCloseModal} />
    </>
  );
};

export default FileDropZone;

import React from 'react';
import CasesFilterBar from 'modules/CasesModule/components/Cases/components/CasesFilterBar';
import { Paper } from '@mui/material';
import CasesList from 'modules/CasesModule/components/Cases/components/CasesList';
import NoCases from 'modules/CasesModule/components/Cases/components/NoCases';
import NothingFound from 'modules/CasesModule/components/Cases/components/NothingFound';

const CasesTable = () => {
  return (
    <Paper sx={{ backgroundColor: '#fff' }}>
      <CasesFilterBar />
      <CasesList />
      {/* <NoCases />
      <NothingFound /> */}
    </Paper>
  );
};

export default CasesTable;

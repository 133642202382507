import { UserRoles } from 'types/user';
import { AuthRoutes } from './constants';
import Cases from 'modules/CasesModule/components/Cases/Cases';
import Dashboard from 'modules/DashboardModule/Dashboard';
import Case from 'modules/CasesModule/components/Case/Case';

interface Route {
  path: AuthRoutes;
  roles: UserRoles[];
  component: JSX.Element;
}

export const roles = [
  UserRoles.admin,
  UserRoles.assistant,
  UserRoles.companyHr,
  UserRoles.consultant,
  UserRoles.talent,
  UserRoles.teamLead,
];

export const routes: Route[] = [
  {
    path: AuthRoutes.profile,
    roles: roles,
    component: <div>Profile</div>,
  },
  {
    path: AuthRoutes.dashboard,
    roles: roles,
    component: <Dashboard />,
  },
  {
    path: AuthRoutes.cases,
    roles: roles,
    component: <Cases />,
  },
  {
    path: AuthRoutes.inbox,
    roles: roles,
    component: <div>Inbox</div>,
  },
  {
    path: AuthRoutes.teamMembers,
    roles: roles,
    component: <div>Team members</div>,
  },
  {
    path: AuthRoutes.clients,
    roles: roles,
    component: <div>Clients</div>,
  },
  {
    path: AuthRoutes.feedback,
    roles: roles,
    component: <div>Feedback</div>,
  },
  {
    path: AuthRoutes.reports,
    roles: roles,
    component: <div>Reports</div>,
  },
  {
    path: AuthRoutes.notifications,
    roles: roles,
    component: <div>Notifications</div>,
  },
  {
    path: AuthRoutes.support,
    roles: roles,
    component: <div>Support</div>,
  },
  {
    path: AuthRoutes.caseById,
    roles: roles,
    component: <Case />,
  },
];

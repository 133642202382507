import { Typography } from '@mui/material';
import { FC } from 'react';
import { ExtendedSvgIconProps } from 'types/general';
import { StyledNavLink } from './styles';

interface NavigationItemProps {
  icon: FC<ExtendedSvgIconProps>;
  text: string;
  path: string;
}

export const NavBarItem = ({ icon: Icon, text, path }: NavigationItemProps) => {
  return (
    <StyledNavLink
      to={path}
      style={({ isActive }) => {
        return {
          backgroundColor: isActive ? '#05D27914' : 'initial',
          pointerEvents: isActive ? 'none' : 'all',
        };
      }}
    >
      {({ isActive }) => (
        <>
          <Icon variant={isActive ? 'filled' : 'outlined'} color="primary" />
          <Typography
            marginLeft="32px"
            variant="body1"
            color={isActive ? 'primary.dark' : undefined}
          >
            {text}
          </Typography>
        </>
      )}
    </StyledNavLink>
  );
};

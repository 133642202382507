import { Box, Checkbox, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';

const flexStyles = { display: 'flex', alignItems: 'center' };

const CasesListHeader = () => {
  return (
    <TableHead>
      <TableRow sx={{ display: 'flex', width: '100%', height: '56px' }}>
        <TableCell width='25%' sx={flexStyles}>
          <Checkbox />
          <Box ml='8px'>
            <Typography variant='body2'>Talent</Typography>
          </Box>
        </TableCell>
        <TableCell width='12.5%' sx={flexStyles}>
          <Typography variant='body2'>Client</Typography>
        </TableCell>
        <TableCell width='12.5%' sx={flexStyles}>
          <Typography variant='body2'>Created date</Typography>
        </TableCell>
        <TableCell width='12.5%' sx={flexStyles}>
          <Typography variant='body2'>Status</Typography>
        </TableCell>
        <TableCell width='12.5%' sx={flexStyles}>
          <Typography variant='body2'>Case type</Typography>
        </TableCell>
        <TableCell width='12.5%' sx={flexStyles}>
          <Typography ml='8px' variant='body2'>
            Priority
          </Typography>
        </TableCell>
        <TableCell width='12.5%' sx={flexStyles}>
          <Typography variant='body2'>Agent</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CasesListHeader;

import React, { FC } from 'react';
import { TablePagination } from '@mui/material';
import theme from 'MuiTheme';

const paginationStyles = {
  '.MuiTablePagination-selectLabel': {
    color: theme.palette.text.secondary,
  },
  '.MuiSvgIcon-root': {
    color: theme.palette.text.secondary,
  },
};

interface IProps {
  count: number;
  page: number;
  onPageChange: () => void;
  rowsPerPage: number;
  onRowsPerPageChange: () => void;
}

const CasesPagination: FC<IProps> = (props) => <TablePagination sx={paginationStyles} component='div' {...props} />;

export default CasesPagination;

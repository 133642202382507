import { UserRoles } from 'types/user';

export function getAccessiblePages<T extends { roles: UserRoles[] }>(
  userRole: UserRoles,
  pages: T[]
): T[] {
  const availablePages = pages.filter((page) => page.roles.includes(userRole));

  return availablePages;
}
